.stringAssign {
    padding-bottom: 1em;
    display: flex;
  }
  
  .editor-content {
    border: 1px solid #f1f1f1;
    padding: 10px;
    height: auto;
    max-height: 200px;
    
  
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
  
    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }
  
    &::-webkit-scrollbar:vertical {
      width: 11px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.25);
      border: 2px solid #fff;
      border-radius: 8px;
    }
  }
  
  .editor {
    height: 230px;
  
    p {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
  
  .editor-wrapper {
    height: 280px;
  }
  
  .wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  
  .editorWrapper {
    background-color: white;
    padding: 40px;
    border: 2px solid black;
    border-radius: 10px;
    box-shadow: inset 0 0 20px #000;
    margin-bottom: 20px;
  }
  
  .multiLocaleControlsWrapper {
    margin-bottom: 13px;
  }
  
  .localeButton {
    margin-left: auto;
  }
  
  .generateBtn {
    align-self: center;
    border: 1px solid #0080ff;
    color: #0080ff;
    padding: 5px 40px;
    border-radius: 10px;
    margin-left: -70%;
    margin-right: 140%;
  
    &:hover:not(:disabled) {
      background-color: #0080ff;
      color: #fff;
    }
  
    &:disabled {
      opacity: 0.5;
    }
  }
  